<template>
  <v-container fluid class="mb-16">
    <v-card height="100%">
     
      <v-row>
        <v-card-text style="background: white; height: 100%">
          <v-row class="ml-7" style="width: 96%">
            <v-col>
              <template>
                <v-toolbar elevation="2" style="background: #f5f5f5">
                  <v-toolbar-title class="mt-2 mb-2 ml-1">
                    Product Stock Report
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                   <v-row class="mt-5">
                    <!-- Data download and print option  -->
                    <v-col cols="4">
                        <v-card-title class="pb-10 ">
                            <!-- <v-spacer></v-spacer> -->
                            <v-btn @click="exportTableToCSV('product_stock_report.csv')" text>
                              <v-icon class="pr-5" large color="black">mdi-download </v-icon>
                            </v-btn>
                            <v-btn text @click="print">
                              <v-icon class="pr-5" large color="black">mdi-printer </v-icon>
                            </v-btn>
                        </v-card-title>                                                
                    </v-col>
                        <!-- Date filtering  -->
                    <v-col cols="8" class="d-flex flex-row mt-4" style="column-gap:10px">
                      <v-spacer></v-spacer>
                      <v-col cols="4">
                        <v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateto1" label="From Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                              v-on="on" dense style="padding:0px !important"></v-text-field>
                          </template>
                          <v-date-picker v-model="dateto1" @input="menu = false"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="4">
                        <v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
                          transition="scale-transition" offset-y min-width="290px">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dateto" label="To Date" prepend-icon="mdi-calendar" readonly v-bind="attrs"
                              v-on="on" dense style="padding:0px !important"></v-text-field>
                          </template>
                          <v-date-picker v-model="dateto" @input="menu2=false"></v-date-picker>
                        </v-menu>
                      </v-col>
      
                      <!-- <v-btn color="white" @click="todate">
                        To Date
                        <v-icon color="black" right>mdi-calendar-month </v-icon>
                      </v-btn> -->
      
                      <v-btn text @click="seereport" class="mr-2 font-weight-bold" style="background: gray">
                        Submit
                      </v-btn>
                    </v-col>
                </v-row>
                  <!-- <div class="pr-5 pb-5 pt-5">
                    <v-text-field
                      v-model="searchseller"
                      append-icon="mdi-magnify"
                      label="Search"
                      filled
                      rounded
                      dense
                      hide-details
                    ></v-text-field>
                  </div> -->
                  ​
                  <!-- <div>
                    <v-btn text @click="print" class="ml-10">
                      <v-icon large color="black">mdi-printer </v-icon>
                    </v-btn>
                  </div> -->
                  <!-- <v-switch
                      v-model="singleExpand"
                      label="Single expand"
                      class="mt-5"
                    ></v-switch>  v-if="item.Brand_name != 'Individual' "-->
                </v-toolbar>
                ​
                <div id="dataapp">
                  <v-data-table
                  :headers="SellerHeader"
                  :items="specifications"
                  :search="searchseller"
                  :server-items-length="totalItems1" 
                  class="elevation-1"
                  style="background: #f5f5f5 elevation-1"
                  :loading="loading"
                  loading-text="Loading... Please wait"
                  hide-default-footer
                  disable-pagination
                >
                <template v-slot:top >
                  <v-card-title>
                      <v-spacer></v-spacer>
                      <div class="pr-4 pb-4 pt-2 d-flex ">
                          <div class="search g">
                              <v-text-field v-model="search_key" label="Search by product id" filled rounded   dense hide-details>
                              </v-text-field>
                          </div>
                          <div class="icon-search pr-4 pb-5 pt-2">
                              <v-btn icon style="background: red"><v-icon      @click="searchHandleStockReport">mdi-magnify</v-icon>
                              </v-btn>                                                          
                          </div>
                          
                      </div>
                  </v-card-title>
                </template>
                <template slot="body.append">
                  <tr>
                    <th class="h4">Totals</th>                   
                    <th class="h6"></th>
                    <th class="h6"></th>
                    <th class="h6"></th>
                    <th class="h6">{{ sumField("available_quantity") }}</th>
                    <th class="h6">{{ sumField("quantity") }}</th>                                                                        
                    <th class="h6">{{ sumField("purchase_price") }}</th>
                    <th class="h6">{{ sumField("total_buying_price") }}</th>
                    <th class="h6">{{ sumField("selling_price") }}</th>
                    <th class="h6">{{ sumField("total_selling_price") }}</th>
                  </tr>
                </template>
                </v-data-table>
                </div>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-row>
     
      <div class="d-flex justify-content-center mt-5">
        <!-- <v-col cols="3" md="3"> -->
        <div class="col-4">
            Total {{ totalItems1 }} records
        </div>
        <!-- </v-col> -->
        <!-- <v-col cols="3" md="3"> -->
        <div class="col-4">
            <v-select dense outlined hide-details v-model="perPage1" label="Items per page" @change="handlePageSizeChange" :items="pageSizes1" >
            </v-select>
            <!-- <span>Show</span>
             @change="handlePageSizeChange($event)" -->
            <!-- <select v-model="perPage" @change="handlePageSizeChange($event)">
                <option v-for="size in pageSizes" :key="size" :value="size">{{ size }}</option>
            </select> --> 

        </div>
        <!-- </v-col> -->
        <!-- <v-col cols="6" md="3" > -->
        <div class="col-4">
            <v-pagination v-model="currentPage1" :length="totalPages1" total-visible="7" @input="handlePageChange">
            </v-pagination>
        </div>

        

        <!-- </v-col> -->
    </div>
    <!-- add modal for page all section  -->

    <div class="text-center">
      <v-dialog
        v-model="dialog"
        width="500"
      >
        <template v-slot:activator="{ on, attrs }">

        </template>
  
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Privacy Policy
          </v-card-title>
  
          <v-card-text>
            Your request is accecpted.We will send you a Pdf file in your mail. Thanks
          </v-card-text>
          <v-divider></v-divider>
  
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="primary"
              text
              @click="dialog = false"
            >
              Close
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>

    <!-- close modal  -->
    
    </v-card>
   
    ​
  </v-container>
</template>
​
​
​
<script>
export default {
  data: () => ({
    dialog: false,
    loading: true,
    searchseller: "",
    text : "",
    search_key:null,
    dateto1: null,
    dateto: null,
    menu2: false,
    menu: false,
    perPage1: '10',
    currentPage1: 1,
    totalItems1: 0,
    totalPages1: 0,
    pageSizes1: ['10', '30', '50', '100', 'All'],
    SellerHeader: [
      //   {
      //   text: "Date",
      //   value: "seller_name",
      //   sortable: false,
      // },
      {
        text: "Product ID",
        value: "product_id",
        sortable: false,
      },
      //{ text: "Barcode", value: "product_barcode", sortable: false },
      { text: "Product Name", value: "product_name", sortable: false },
      { text: "Size", value: "product_size", sortable: false },
      { text: "Category", value: "category_name", sortable: false },
      {
        text: "Available Quantity",
        value: "available_quantity",
        sortable: false,
      },
      { text: "Out Quantity", value: "quantity", sortable: false },
      { text: "Buying Price", value: "purchase_price", sortable: false },
      { text: "Total Buying Price", value: "total_buying_price", sortable: false },
      { text: "Selling Price", value: "selling_price", sortable: false },
      { text: "Total Selling Price", value: "total_selling_price", sortable: false },
      { text: "", value: "actions", sortable: false },
    ],
    tickets: [],
    contact: {},
    specifications: [],
    sellerPdetails: {},
  }),
  methods: {
    searchHandleStockReport(event){
      this.initialize();

    },
    handlePageSizeChange(event) {
      // console.log('page size change method called');
      // console.log('event = ', event);
      this.perPage1 = (event);
      this.loading = true;
      this.initialize();
    },

    handlePageChange(value) {
      // console.log('handle page change value = ', value);
      this.currentPage1 = value;
      this.loading = true;
      this.initialize();
      window.scrollTo(0, 0);
    },
    //This api is for demo testing with child site data
   async initialize() {
      let perPage ;
      if (this.perPage1 === 'All'){
        this.dialog= true;
        await axios.get (`site/product_sales_report_pdf_thread/`).then((response)=>{
          console.log("thread is :", response);
        })
      
        console.log("dialog is:", this.dialog);
        perPage = this.totalItems1;
      }
      else
      {
        perPage = this.perPage1;
      }
      this.loading = true;
     await axios
        .get(`site/get_productstockreport1/?page=${this.currentPage1}&size=${perPage}&FromDate=${this.dateto1}&ToDate=${this.dateto}&search_key=${this.search_key}`)
        .then((response) => {
          let beforeCustomization = [];
          this.specifications = response.data.results;
          // beforeCustomization.forEach((element) => {
          //   element.forEach((childElement) => {
          //     this.specifications.push(childElement);
          //   });
          // });
          // console.log(this.specifications);
          this.totalItems1 = response.data.count;
          this.totalPages1 = parseInt(this.totalItems1 / this.perPage1);
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    sumField(key) {
      // sum data in give key (property)
      return this.specifications.reduce((a, b) => a + (b[key] || 0), 0);
    },

    downloadCSV(csv, filename){
      var csvFile;
      var downloadLink;
      
      csvFile = new Blob([csv], {type: 'text/csv'});
      downloadLink = document.createElement("a");
      downloadLink.download = filename;
      downloadLink.href = window.URL.createObjectURL(csvFile);
      downloadLink.style.display = "none";

      document.body.appendChild(downloadLink);
      downloadLink.click();
    },
    
    exportTableToCSV(filename) {                
      var csv = [];
      var rows = document.querySelectorAll(" #dataapp table tr");
    
      for(var i=0; i<rows.length; i++) {
        var row = [], cols = rows[i].querySelectorAll("td, th");
        for( var j=0; j<cols.length; j++)
          row.push(cols[j].innerText);
        csv.push(row.join(","));
      } 
      
      this.downloadCSV(csv.join("\n"), filename);
       },
    // This Link will be Change for link change
    print() {
      var divToPrint = document.getElementById("dataapp");
      // var newWin = window.open("");
      // newWin.document.write(divToPrint.outerHTML);
      // newWin.print();

      //newWin.close();

      var style = "<style>";
      style = style + "table {width: 100%;font: 17px Calibri;}";
      style =
        style +
        "table, th, td {border: solid 1px #DDD; border-collapse: collapse;";
      style = style + "padding: 2px 3px;text-align: center;}";
      style = style + "</style>";

      var win = window.open("", "", "height=1900,width=1900");
      win.document.write(style); //  add the style.
      win.document.write(divToPrint.outerHTML);
      win.document.close();
      win.print();
      // let routeData = this.$router.resolve({
      //   name: `site/get_productstockreport_pdf/`,
      //   // name: `http://127.0.0.1:8000/site/get_productstockreport_pdf/`
      // });
      // routeData.href = axios.defaults.baseURL+`site/get_productstockreport_pdf/`;
      // // routeData.href = `http://127.0.0.1:8000/site/get_productstockreport_pdf/`;
      // window.open(routeData.href, "_blank");
    },
    fromdate() { },
    seereport() {
      // console.log('this from  date is :', this.dateto1);
      // console.log('this to  date is :', this.dateto);
      this.initialize();
    },
    todate() { },
  },
  mounted() {
    this.initialize();
  },
};
</script>
​
​
​
<style >
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-size: 1.2rem;
  height: 48px;
}
</style>